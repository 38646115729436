import React from 'react';
import LoginDialog from '../../components/Authentication/LoginDialog';

function Login(){
    return(
        <React.Fragment>
            <LoginDialog />
        </React.Fragment>
    );
}

export default Login;